select {
  width: 100%;
  padding: 8px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 12px;
}

.jobs input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.client input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-datepicker-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker {
  transform: translate(-18%, 45px);
}


.slick-prev::before,.slick-next::before {
  font-size: 28px !important;
  color: rgba(0, 0, 0, 0.272) !important;
  /* Change as needed */
}

.slick-next {
  right: 25px !important
  
}

.slick-prev {
  left: 25px !important;
  z-index:999999
}

