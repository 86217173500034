/* CustomStyles.css */
.rbc-calendar {
    background: #f9f9f9;
    margin-top: 15px;
  }
  
  .rbc-event {
    background-color: #3174ad;
    color: white;
    padding: 2px;
  }
  
  .rbc-event-content{
    font-size: 12px;
  }

  .rbc-time-view .rbc-row {
    min-height: 30px !important ;
  }